<template>
  <section id="milestones" class="pt-4 pb-5 text-white force-limit-width">
    <SectionTitleHeader :title="$t('message.milestones')" color="history-dark " class="pt-4 px-4 mb-5" />
    <section class="horizontal-scroll-section">
      <h3 class="subsection-title px-4 font-weight-bold" data-aos="zoom-in">
        {{ $t('message.play-streak') }}
      </h3>
      <div class="horizontal-scroll-wrapper">
        <MilestonesScroll class="--variant-play-streak mt-3" :items="streaks_milestones" :value="userModel.play_streak">
          <template v-slot="{ item, focused }">
            <Milestone variant="play-streak" :disabled="!item.earned" :highlight="item.lastEarned" :class="[focused ? '--size-130px' : '--size-85px']" :milestone="item" />
          </template>
        </MilestonesScroll>
      </div>
      <p class="legend px-4 font-weight-regular">
        {{ $tc('plurals.day', userModel.play_streak) }}
      </p>
    </section>
    <div class="d-flex justify-content-center">
      <div class="app-divider mt-3 --thin bg-white mb-5"></div>
    </div>
    <section class="horizontal-scroll-section">
      <h3 class="subsection-title px-4 font-weight-bold" data-aos="zoom-in">
        {{ $t('message.total-points') }}
      </h3>
      <div class="horizontal-scroll-wrapper">
        <MilestonesScroll class="--variant-points mt-3" :items="points_milestones" :value="userModel.points">
          <template v-slot="{ item, focused }">
            <Milestone variant="points" :disabled="!item.earned" :highlight="item.lastEarned" :class="[focused ? '--size-130px' : '--size-85px']" :milestone="item" />
          </template>
        </MilestonesScroll>
      </div>

      <p class="legend px-4 font-weight-regular">
        {{ $tc('plurals.points', userModel.points) }}
      </p>
    </section>
  </section>
</template>

<script>
// @ is an alias to /src
import Milestone from '@/components/Milestone.vue';
import MilestonesScroll from '@/components/MilestonesScroll.vue';
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';

export default {
  name: 'Home',
  data: () => ({
    streaks_milestones: [
      { value: 7 },
      { value: 15 },
      { value: 30 },
      { value: 60 },
      { value: 90 },
      { value: 100 },
      { value: 150 },
      { value: 200 },
      { value: 250 },
      { value: 300 },
      { value: 350 },
      { value: 365 },
      { value: 400 },
      { value: 450 },
      { value: 500 },
      { value: 600 },
      { value: 700 },
      { value: 730 },
      { value: 800 },
      { value: 900 },
      { value: 950 },
      { value: 1000 }
    ],
    points_milestones: [
      { value: 10 },
      { value: 25 },
      { value: 50 },
      { value: 100 },
      { value: 200 },
      { value: 300 },
      { value: 500 },
      { value: 750 },
      { value: 1000 },
      { value: 1200 },
      { value: 1500 },
      { value: 1800 },
      { value: 2000 },
      { value: 2500 },
      { value: 3000 },
      { value: 3500 },
      { value: 4000 },
      { value: 4500 },
      { value: 5000 },
      { value: 6000 },
      { value: 7000 },
      { value: 8000 },
      { value: 9000 },
      { value: 10000 },
      { value: 11000 },
      { value: 12000 },
      { value: 13000 },
      { value: 14000 },
      { value: 15000 },
      { value: 16000 },
      { value: 17000 },
      { value: 18000 },
      { value: 19000 },
      { value: 20000 }
    ]
  }),
  props: { userModel: { type: Object, required: true } },
  components: {
    Milestone,
    MilestonesScroll,
    SectionTitleHeader
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

#milestones {
  background: $gradient-science;
}

.horizontal-scroll-section {
  width: 100%;
}
.horizontal-scroll-wrapper {
  height: 150px;
}
</style>
