<template>
  <section id="statistics" class="force-limit-width text-black pt-4 pb-5 px-4">
    <SectionTitleHeader :title="$t('message.your-statistics')" color="science-dark" textColor="black" class="mt-4 mb-5" />
    <div class="statistics-content-wrapper p-4">
      <ul class="d-flex flex-column align-items-center">
        <li>
          <div class="stat pb-3 px-2" data-aos="fade-up" data-aos-offset="200">
            <p class="stat-value font-weight-bold text-science-dark d-flex align-items-center justify-content-center">
              <span ref="question_answered">0</span>
              <img class="mx-2" :src="getIcon('questions-answered')" alt="" width="27" height="33" />
            </p>
            <p class="stat-description mb-0 text-black">
              {{ $t('message.questions-answered') }}
            </p>
          </div>
        </li>
        <li>
          <div class="stat pb-3 px-2" data-aos="fade-up" data-aos-offset="200">
            <p class="stat-value font-weight-bold text-science-dark d-flex align-items-center justify-content-center">
              <span ref="overall_right_answer">0</span>%
              <img class="mx-2" :src="getIcon('overall-right-answers')" alt="" width="25" height="25" />
            </p>
            <p class="stat-description mb-0 text-black">
              {{ $t('message.overall-right-answers') }}
            </p>
          </div>
        </li>
      </ul>
      <div class="d-flex justify-content-center">
        <div class="app-divider mt-3 --thin bg-light-gray mb-5"></div>
      </div>
      <div class="d-flex justify-content-center">
        <ul class="d-grid custom-grid-template mx-auto">
          <li v-for="(type, index) in badges_types" :key="type" data-aos="zoom-in" :data-aos-delay="index % 2 == 1 ? '250' : '0'">
            <Badge :type="type" :value="userModel.badges[underline(type)]" />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
import Badge from '@/components/Badge.vue';
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';

export default {
  props: { userModel: { type: Object, required: true } },
  data: () => ({
    intersectionObserver: [],

    badges_types: ['arts-and-entertainment', 'general-knowledge', 'geography', 'history', 'literature', 'science']
  }),
  mounted() {
    const opt = { threshold: [0] };
    this.intersectionObserver = [
      new IntersectionObserver(this.onQuestionAnsweredVisible.bind(this), opt),
      new IntersectionObserver(this.onOverallRightAnswerVisible.bind(this), opt)
    ];
    this.intersectionObserver[0].observe(this.$refs.question_answered);
    this.intersectionObserver[1].observe(this.$refs.overall_right_answer);
  },
  destroyed() {
    this.intersectionObserver[0].disconnect();
    this.intersectionObserver[1].disconnect();
  },
  methods: {
    onQuestionAnsweredVisible(entries) {
      if (entries[0].isIntersecting === true) {
        this.numbering(this.$refs.question_answered, this.userModel.question_answered);
        this.intersectionObserver[0].disconnect();
      }
    },
    onOverallRightAnswerVisible(entries) {
      if (entries[0].isIntersecting === true) {
        this.numbering(this.$refs.overall_right_answer, this.userModel.overall_right_answer);
        this.intersectionObserver[1].disconnect();
      }
    },
    numbering: function(dom, value, duration, delay) {
      if (!dom) {
        return;
      }
      if (!duration) {
        duration = 1000;
      }
      if (!delay) {
        delay = 750;
      }
      if (!value) {
        value = 0;
        return;
      }
      value = parseInt(value);
      let initialDate = Date.now() + delay;
      function loop() {
        const delta = Date.now() - initialDate;
        if (delta > duration) {
          dom.innerText = value;
          return;
        }
        let factor = delta / duration;
        factor *= factor;
        dom.innerText = parseInt(value * factor);
        requestAnimationFrame(loop);
      }
      setTimeout(function() {
        requestAnimationFrame(loop);
      }, delay);
      dom.innerHTML = 0;
    },
    getIcon(icon) {
      return require(`@/assets/images/${icon}.svg`);
    },
    underline(text) {
      return text.replace(/-/g, '_');
    }
  },
  components: { Badge, SectionTitleHeader }
};
</script>
<style scoped lang="scss">
@import '@/assets/custom-vars/_colors.scss';

#statistics {
  background: $gradient-general-knowledge;

  .statistics-content-wrapper {
    background: linear-gradient(180deg, #e0fbf1 0%, #d5f5e9 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    .custom-grid-template {
      grid-template-columns: 100px 100px;
      column-gap: 66px;
    }
    > ul > li {
      margin: 0px;
      .stat {
        border-radius: 32px;
      }
    }
  }
}
</style>
