<template>
  <div
    class="certification certification-bg d-flex flex-column align-items-center justify-content-center"
    ref="parent"
    :style="{ backgroundImage: backgroundImage }"
  >
    <Milestone
      :variant="milestone_variant"
      :highlight="true"
      class="--size-166px --legacy-mode"
      :milestone="milestone_computed"
    />
    <div class="ribbon" :style="{ backgroundImage: ribbonImage }">
      <svg viewBox="0 0 121.35555 21.872221" width="344" height="62">
        <g transform="translate(-39.863892,-104.96865)">
          <path
            id="curve"
            style="fill: none; stroke: none"
            d="m 54.641997,116.27254 c 24.214369,-1.10679 51.682733,-2.32731 91.406173,0"
          />
          <text
            width="121"
            fill="white"
            font-family="'Greycliff Regular', sans-serif"
            style="text-anchor: middle; font-weight: bold"
            letter-spacing="0.75"
          >
            <textPath xlink:href="#curve" font-size="6px" startOffset="50%">
              {{ $t('message.milestone-achieved') }}
            </textPath>
          </text>
        </g>
      </svg>
    </div>
    <p
      class="legend --big mt-2 text-dark-gray font-weight-regular"
      style="font-weight: bold; max-width: 230px"
    >
      <i>
        <template v-if="milestone.play_streak">
          {{ $t('message.i-have-played-qotd-in-a-row', [milestone.play_streak]) }}
        </template>
        <template v-else-if="milestone.points">
          {{ $t('message.i-have-points-on-qotd', [milestone.points]) }}
        </template>
      </i>
    </p>
  </div>
</template>
<script>
import Milestone from '@/components/Milestone.vue';

export default {
  props: { userModel: { type: Object, required: true } },
  components: {
    Milestone,
  },
  computed: {
    backgroundImage() {
      return `url(${require('@/assets/images/certification_bg.svg')})`;
    },
    ribbonImage() {
      return `url(${require('@/assets/images/certification_ribbon.svg')})`;
    },
    milestone() {
      return this.userModel.last_milestone;
    },
    milestone_variant() {
      if (this.userModel.last_milestone.play_streak) {
        return 'play-streak';
      } else if (this.userModel.last_milestone.points) {
        return 'points';
      }
      return '';
    },
    milestone_computed() {
      if (this.userModel.last_milestone.play_streak) {
        return { value: this.userModel.last_milestone.play_streak };
      } else {
        return { value: this.userModel.last_milestone.points };
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/custom-vars/_colors.scss';
.certification {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 400px;
  height: 400px;
  z-index: -1;
  visibility: hidden;
  background-size: 100% 100%;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  border: 1px solid black;
  &.rendering {
    visibility: initial;
  }
  > .ribbon {
    width: 344px;
    height: 62px;
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-repeat: no-repeat;
  }
}
.inner-shadow {
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.4);
}
.outer-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}
.rounded-corners {
  border-radius: 12px;
}
</style>
