const API_URL = "https://9ncpzlnj87.execute-api.us-east-1.amazonaws.com/qotd-service---user-profile-dev"

class MODEL {
  getTopicData(topic) {
    let data = { response: "general_knowledge", id: "general knowledge" }
    switch (topic) {
      case "arts and entertainment":
      case "artes e entretenimento":
      case "arts et divertissement":
      case "arte y entretenimiento":
      case "arti e intrattenimento":
        data = { response: "arts_and_entertainment", id: "arts and entertainment" }
        break
      case "literature":
      case "literatura":
      case "letteratura":
      case "littérature":
        data = { response: "literature", id: "literature" }
        break
      case "science":
      case "ciências":
      case "sciences":
      case "ciencia":
      case "scienza":
        data = { response: "science", id: "science" }
        break
      case "geography":
      case "geografia":
      case "géographie":
      case "geografía":
        data = { response: "geography", id: "geography" }
        break
      case "history":
      case "histoire":
      case "historia":
      case "história":
      case "storia":
        data = { response: "history", id: "history" }
        break
    }
    return data
  }
  getPointsByBadge(body) {
    const pointsByBadges = (body.badges || []).reduce(
      (acc, badge) => {
        acc[this.getTopicData(badge).response]++
        return acc
      },
      {
        arts_and_entertainment: 0,
        general_knowledge: 0,
        literature: 0,
        science: 0,
        geography: 0,
        history: 0,
      }
    )
    return pointsByBadges
  }
  compute(body) {
    const userModel = {
      play_streak: body.consecutive_days,
      points: body.total_points,
      badges: this.getPointsByBadge(body),
      question_answered: body.total_answers,
      overall_right_answer: Math.round((body.correct_answers / body.total_answers) * 100),
    }
    return userModel
  }
  requestData(axios, profileApiId) {
    const params = { params: { profileApiId } }
    return axios
      .get(API_URL+"/get-profile", params)
      .then((response) => {
        console.log(response)
        if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
          return this.compute(response.data.body)
        } else {
          alert("Fail to load your profile! Try it again! Reason: " + response.data.message)
          return null
        }
      })
      .catch(function(error) {
        console.error(error)
        alert("Fail to load your profile! Try it again!")
      })
  }
  get(axios, profile_id = 1, query) {
    return new Promise((resolve) => {
      if (profile_id === "test_id") {
        profile_id = "4614e356-906c-49ef-8198-8a516e1f33ff"
      }
      this.requestData(axios, profile_id).then((usermodel) => {
        const userModel = { ...usermodel }
        if (query.milestone === "points") {
          userModel.last_milestone = { points: query.value }
        } else if (query.milestone === "dayStreak") {
          userModel.last_milestone = { play_streak: query.value }
        }
        resolve(userModel)
      })
    })
  }
}

export default new MODEL()
