<template>
  <div
    class="milestone img-wrapper"
    :class="[
      '--variant-' + variant,
      highlight
        ? '--show-highlight img-wrapper-before --background_milestone-on-before'
        : ' ',
      disabled ? '--filter-grayscale' : '',
    ]"
    :style="{ backgroundImage: milestoneImage }"
  >
    <div class="milestone-text">
      {{ milestone.value }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    variant: { type: String, required: false, default: () => 'play-streak' },
    disabled: { type: Boolean, required: false, default: () => false },
    highlight: { type: Boolean, required: false, default: () => false },
    milestone: { type: Object, required: true },
  },
  computed: {
    milestoneImage() {
      if (this.disabled) {
        if (this.variant == 'play-streak') {
          return `url(${require('@/assets/images/play_streaks_milestone-grayscale.png')})`;
        } else if (this.variant == 'points') {
          return `url(${require('@/assets/images/points_milestone-grayscale.png')})`;
        }
      } else {
        if (this.variant == 'play-streak') {
          return `url(${require('@/assets/images/play_streaks_milestone.png')})`;
        } else if (this.variant == 'points') {
          return `url(${require('@/assets/images/points_milestone.png')})`;
        }
      }
      return '';
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/custom-vars/_colors.scss';

.milestone {
  transition: width 0.25s ease-out, height 0.25s ease-out, margin 0.25s ease-out;
  will-change: width, height, margin;
  margin: 0px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  > .milestone-text {
    font-weight: bold;
    transition: margin-bottom 0.25s ease-out, font-size 0.25s ease-out;
    font-family: 'Alfa Slab One';

    background-color: #2e2e2e;
    color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }
  &.--show-highlight::before {
    content: '';
    position: absolute;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    transition: width 0.25s ease-out, height 0.25s ease-out;
  }
  &.--variant-points {
    > .milestone-text {
      background-color: $geography-darker;
      text-shadow: 0px 2px 3px rgba($geography-dark, 0.5);
    }
  }
  &.--variant-play-streak {
    > .milestone-text {
      background-color: $history-darker;
      text-shadow: 0px 2px 3px rgba($history-dark, 0.5);
    }
  }
  &.--legacy-mode.--variant-play-streak {
    > .milestone-text {
      margin-left: 2px;
      background-color: transparent !important;
      text-shadow: none !important;
      color: rgba($history-darker, 0.75) !important;
    }
  }
  &.--legacy-mode.--variant-points {
    > .milestone-text {
      margin-left: 2px;
      background-color: transparent !important;
      text-shadow: none !important;
      color: rgba($geography-darker, 0.75) !important;
    }
  }
  &.--size-210px {
    .milestone-text {
      font-size: 24px;
      line-height: 43px;
      margin-bottom: 30px;
    }
  }
  &.--size-166px {
    .milestone-text {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 30px;
    }
  }
  &.--size-130px {
    .milestone-text {
      font-size: 14px;
      margin-bottom: 18px;
    }
  }
  &.--size-85px {
    .milestone-text {
      margin-bottom: 12px;
      font-size: 10px;
    }
  }
}
</style>
