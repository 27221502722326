<template>
  <div class="d-flex flex-column align-items-center app-badge mb-5 mt-2">
    <img :src="badgeImage" alt="" class="img-wrapper --size-100px" />
    <div class="badge-title d-flex align-items-center mt-2">
      <p class="legend --big font-weight-bold">{{ $t('badges.' + type) }}</p>
    </div>
    <div class="badge-value bg-white d-flex align-items-center">
      <img
        class="badge-star"
        :class="['bg-' + type]"
        :style="{ backgroundImage: badgeStar }"
        alt=""
      />
      <p class="text-black legend font-weight-bold text-center mb-0">{{ value }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: { type: String, required: true },
    value: { type: Number, required: true },
  },
  computed: {
    badgeImage() {
      return require(`@/assets/images/badge-${this.type}.svg`);
    },
    badgeStar() {
      return 'url(' + require(`@/assets/images/badge-star.svg`) + ')';
    },
  },
};
</script>
<style lang="scss">
.app-badge {
  width: 100px;
  max-width: 100px;
  > .badge-title {
    height: 47px;
  }
  > img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  > .badge-value {
    width: 51px;
    height: 26px;
    border-radius: 12px;
    transform: translateX(8px);
    > .badge-star {
      width: 32px;
      height: 32px;
      border: 3px solid white;
      border-radius: 50%;
      background-size: 14px 14px;
      background-repeat: no-repeat;
      background-position: center center;
      transform: translateX(-16px);
      position: absolute;
    }
    > p {
      transform: translateX(12px);
      width: 32px;
    }
  }
}
</style>
