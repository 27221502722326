<template>
  <div
    class="horizontal-scroll"
    ref="parent"
    data-aos="fade-in"
    data-aos-offset="150"
    @mousedown.passive="onMouseDown"
    @mousemove.passive="onMouseMove"
    @mouseup.passive="onMouseUp"
    @touchstart.passive="onMouseDown"
    @touchmove.passive="onMouseMove"
    @touchend.passive="onMouseUp"
    @touchcancel.passive="onMouseUp"
  >
    <div class="horizontal-scroll-content d-flex align-items-center" ref="child">
      <template v-if="start !== 0">
        <div class="view-more-item bg-orange-lighter" @click="showMoreLeft">
          <span class="legend font-weight-regular">
            {{ $t('message.view-more') }}
          </span>
        </div>
        <div class="horizontal-scroll-divider">
          <div class="horizontal-scroll-divider-value" style="width: 100%" />
        </div>
      </template>
      <template v-for="(item, index) in computedItems">
        <div
          class="horizontal-scroll-item"
          :class="[focusedIndex == index ? 'focused' : '']"
          :key="'item-' + item.value"
          @click="focusOnItemIndex(index, true)"
        >
          <slot :item="item" :focused="focusedIndex == index"></slot>
        </div>
        <div
          class="horizontal-scroll-divider"
          v-if="index + 1 < computedItems.length"
          :key="'pb-' + item.value"
        >
          <div
            class="horizontal-scroll-divider-value"
            ref="dividers"
            :data-interval="[item.value, computedItems[index + 1].value]"
          />
          <div v-if="item.lastEarned" class="horizontal-scroll-divider-dot"></div>
        </div>
      </template>
      <template v-if="end !== items.length">
        <div class="horizontal-scroll-divider"></div>
        <div class="view-more-item bg-orange-lighter" @click="showMoreRight">
          <span class="legend font-weight-bold">
            {{ $t('message.view-more') }}
          </span>
        </div>
      </template>
    </div>
    <img
      alt=""
      :class="{
        show: lastItemIndexEarned != focusedIndex,
        onRight: lastItemIndexEarned < focusedIndex,
      }"
      :src="chevronImg"
      class="warp-to-last-earned"
      @click="focusOnItemIndex(lastItemIndexEarned, true)"
    />
  </div>
</template>
<script>
const easings = {
  'ease-in-out': function (t) {
    if (t <= 0.5) return 2.0 * t * t;
    t -= 0.5;
    return 2.0 * t * (1.0 - t) + 0.5;
  },
  'ease-in': function (t) {
    return t * t;
  },
  'ease-out': function (t) {
    return Math.sqrt(t);
  },
  linear: function (t) {
    return t;
  },
};

export default {
  props: {
    items: { type: Array, required: true },
    value: { type: Number, required: true },
    total_visible: { type: Number, required: false, default: () => 3 },
  },
  data: () => ({
    start: 0,
    end: 0,

    computedItems: [],
    computedItemsRef: [],

    resizeObserver: false,
    intersectionObserver: false,
    translateX: 0,
    lastItemIndexEarned: 0,
    focusedIndex: 0,

    preventStopAnimation: false,

    RAF: false,

    last_drag_x: false,
    last_drag_y: false,
    last_gforce_dir: false,
    last_gforce_dir_timestamp: false,
    last_gforce_dir_x: false,

    saw: false,
  }),
  created() {
    this.computeItems();
    this.updateItems();
  },
  mounted() {
    this.updateDividers();
    this.updateRefs();
    this.intersectionObserver = new IntersectionObserver(this.onFullyVisible.bind(this), {
      threshold: [1],
    });
    this.setTranslate(this.getCenteredPositionOfItem(0) * -1);

    this.intersectionObserver.observe(this.$refs.parent);
  },
  destroyed() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
    this.intersectionObserver.disconnect();
  },
  computed: {
    chevronImg() {
      return require('@/assets/images/chevron-right.svg');
    },
  },
  methods: {
    showMoreRight() {
      if (this.end === this.items.length) {
        return;
      }
      this.end = Math.min(this.end + this.total_visible, this.items.length);
      this.computedItems = this.items.slice(this.start, this.end);
      this.updateRefs();
      this.$nextTick(() => {
        this.focusOnItemIndex(this.focusedIndex + 1, true);
      });
    },
    showMoreLeft() {
      if (this.start === 0) {
        return;
      }
      const previous = this.start;
      this.start = Math.max(this.start - this.total_visible, 0);
      this.computedItems = this.items.slice(this.start, this.end);
      this.updateItems();
      this.$nextTick(() => {
        this.updateRefs();
        this.updateDividers();
        this.focusedIndex += previous - this.start;
        const x = this.getCenteredPositionOfItem(this.focusedIndex) * -1;
        this.setTranslate(x);
        this.focusOnItemIndex(this.focusedIndex - 1, true);
      });
    },
    computeItems() {
      let index = false;
      this.items.forEach((item, i) => {
        if (item.value <= this.value) {
          index = i;
        }
      });
      this.start = Math.max(index - this.total_visible, 0);
      this.end = Math.min(index + this.total_visible, this.items.length);
      this.computedItems = this.items.slice(this.start, this.end);
    },
    onFullyVisible(entries) {
      if (entries[0].isIntersecting === true) {
        this.intersectionObserver.disconnect();
        this.resizeObserver = new ResizeObserver(() => {
          this.focusIntoLastEarnedItem();
        });
        this.focusIntoLastEarnedItem();
        this.resizeObserver.observe(this.$refs['child']);
      }
    },
    getCenteredPositionOfItem(index) {
      const p = this.$refs.parent;
      const item = this.computedItemsRef[index];
      return item.offsetLeft + item.offsetWidth / 2 - p.offsetWidth / 2;
    },
    showMoreIfNecessary() {
      // auto-load more milestones
      // if (this.focusedIndex === 0) {
      //   this.showMoreLeft();
      // } else if (this.focusedIndex === this.computedItems.length - 1) {
      //   this.showMoreRight();
      // }
    },
    focusOnItemIndex(index, fast) {
      if (this.preventStopAnimation) {
        return;
      }
      this.focusedIndex = index;
      const x = this.getCenteredPositionOfItem(index) * -1;
      if (fast) {
        this.easeToX(x, 250, 'ease-out', this.showMoreIfNecessary);
      } else {
        this.easeToX(x, 2000, 'ease-in-out', this.showMoreIfNecessary);
      }
    },
    focusIntoLastEarnedItem(fromIndex) {
      if (this.preventStopAnimation) {
        return;
      }
      if (fromIndex != undefined) {
        const fromx = this.getCenteredPositionOfItem(fromIndex) * -1;
        this.setTranslate(fromx);
      }
      this.focusOnItemIndex(this.lastItemIndexEarned);
      this.preventStopAnimation = true;
      setTimeout(() => {
        this.preventStopAnimation = false;
      }, 2000);
    },
    setTranslate(x) {
      this.translateX = x;
      this.$refs.child.style.transform = `translateX(${this.translateX}px)`;
    },
    update() {
      this.updateItems();
      this.updateDividers();
      this.focusIntoLastEarnedItem();
    },
    updateItems() {
      let lastItem = false;
      this.lastItemIndexEarned = 0;

      this.computedItems.forEach((item, index) => {
        item.earned = item.value <= this.value;
        item.lastEarned = false;
        if (item.earned) {
          lastItem = item;
          this.lastItemIndexEarned = index;
        }
      });
      if (lastItem) lastItem.lastEarned = true;
    },
    updateRefs() {
      this.computedItemsRef = this.$refs.child.getElementsByClassName(
        'horizontal-scroll-item'
      );
    },
    updateDividers() {
      const dividers = this.$refs.dividers;
      if (dividers) {
        dividers.forEach((divider) => {
          const [from, to] = divider.getAttribute('data-interval').split(',');
          if (this.value >= to) {
            divider.style.width = '100%';
          } else if (this.value < from) {
            divider.style.display = 'none';
          } else if (this.value >= from && this.value < to) {
            const value = ((this.value - from) / (to - from)) * 100;
            divider.style.width = value + '%';
          }
        });
      }
    },
    onMouseDown(evt) {
      if (this.preventStopAnimation) {
        return;
      }
      cancelAnimationFrame(this.RAF);
      if (evt.touches) {
        evt.x = evt.touches[0].clientX;
        evt.y = evt.touches[0].clientY;
      }
      this.last_drag_x = evt.x;
      this.last_drag_y = evt.y;
      this.first_x = evt.x;
      this.dragging = true;
    },
    onMouseMove(evt) {
      if (!this.dragging) {
        return;
      }
      if (evt.touches) {
        evt.x = evt.touches[0].clientX;
        evt.y = evt.touches[0].clientY;
      }
      const deltaY = Math.abs(evt.y - this.last_drag_y);
      let deltaX = evt.x - this.last_drag_x;
      if (deltaX > 0) {
        deltaX = Math.max(deltaX - deltaY, 0);
      } else {
        deltaX = Math.min(deltaX + deltaY, 0);
      }
      this.setTranslate(this.translateX + deltaX);
      let dir = deltaX > 0 ? 1 : -1;
      if (dir !== this.last_gforce_dir) {
        this.last_gforce_dir_timestamp = Date.now();
        this.last_gforce_dir = dir;
        this.last_gforce_dir_x = this.translateX;
      }
      this.last_drag_x = evt.x;
      this.last_drag_y = evt.y;
    },
    onMouseUp() {
      if (this.dragging) {
        this.dragging = false;

        const deltaTime = Date.now() - this.last_gforce_dir_timestamp;
        const deltaX = this.translateX - this.last_gforce_dir_x;

        this.applyForceG((deltaX / deltaTime) * 20);
      }
    },
    getCloserItemIndex() {
      let closerIndex = 0;
      let closerDist = Number.MAX_VALUE;
      const cursorx = -1 * this.translateX;
      for (let i = 0, t = this.computedItems.length; i < t; i++) {
        const dist = Math.abs(this.getCenteredPositionOfItem(i) - cursorx);
        if (dist < closerDist) {
          closerDist = dist;
          closerIndex = i;
        }
      }
      return closerIndex;
    },
    goToCloser() {
      const closerIndex = this.getCloserItemIndex();
      this.focusOnItemIndex(closerIndex, true);
    },
    easeToX(target, duration = 250, easing = 'ease-out', callback) {
      cancelAnimationFrame(this.RAF);
      const from = this.translateX;
      const delta = target - from;

      const startTime = performance.now();
      const ease_method = easings[easing];

      const loop = () => {
        const now = performance.now();
        let t = (now - startTime) / duration;
        t = Math.min(t, 1);
        t = ease_method(t); //ease-in-out
        this.setTranslate(from + t * delta);
        if (t < 1) {
          this.RAF = requestAnimationFrame(loop);
        } else if (callback) {
          this.setTranslate(from + delta);
          callback();
        }
      };

      loop();
    },
    applyForceG(force) {
      cancelAnimationFrame(this.RAF);
      const loop = () => {
        if (Math.abs(force) < 1 || Number.isNaN(force)) {
          return this.goToCloser();
        }
        this.setTranslate(this.translateX + force);
        force /= 1.1;

        this.RAF = requestAnimationFrame(loop);
      };

      loop();
    },
  },
  watch: {
    items() {
      this.update();
    },
    value() {
      this.updateDividers();
      this.focusIntoLastEarnedItem();
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/custom-vars/_colors.scss';

.view-more-item {
  min-width: 64px;
  min-height: 64px;
  border-radius: 50%;
  border: 2px solid $orange-light;
  border-bottom-width: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 800;
    color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-color: $history-darker-2;
  }
}

.warp-to-last-earned {
  position: absolute;
  left: calc(100% - 62px);
  top: calc(50% - 4px);
  width: 50px;
  height: 50px;
  transform: translateY(-50%);
  border: none;
  background-size: contain;
  display: none;
  &.onRight {
    left: 13px;
    transform: translateY(-50%) rotate(180deg);
  }
  &.show {
    display: block;
  }
}
.horizontal-scroll {
  max-width: 100%;
  height: 100%;
  padding-bottom: 8px;
  overflow: hidden;
  user-select: none;
  position: relative;
  > .horizontal-scroll-content {
    height: 100%;
    will-change: transform;
    > .horizontal-scroll-divider {
      z-index: 1;
      min-width: calc((100vw - 205px) / 2);
      max-width: calc((100vw - 205px) / 2);
      will-change: width;
      height: 9px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background: $gradient-grey;
      display: flex;
      > .horizontal-scroll-divider-value {
        height: 100%;
      }
      > .horizontal-scroll-divider-dot {
        height: 9px;
        width: 9px;
        border-radius: 50%;
        margin-left: -4.5px;
      }
    }
    > .horizontal-scroll-item {
      transition: filter 0.25s ease-out;
      filter: brightness(0.85);
      z-index: 2;
      &.focused {
        filter: brightness(1);
      }
    }
  }
  &.--variant-play-streak {
    .horizontal-scroll-divider-value,
    .horizontal-scroll-divider-dot {
      background: $gradient-history;
    }
  }
  &.--variant-points {
    .horizontal-scroll-divider-value,
    .horizontal-scroll-divider-dot {
      background: $gradient-geography;
    }
  }
}
</style>
