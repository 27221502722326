<template>
  <section id="congratulations" class="pb-5 text-white force-limit-width">
    <h2 class="section-title font-weight-bold mb-2 mt-5" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
      {{ $t('message.congratulations') }}
    </h2>
    <h3 class="section-subtitle" data-aos="fade-down" data-aos-delay="1000" data-aos-duration="1000" data-aos-once="true">
      {{ $t('message.you-have-reached-a-milestone') }}
      <br />
      {{ $t('message.on') }}
      <span class="font-weight-700"> {{ $t('app.title') }}:</span>
    </h3>
    <div class="d-flex justify-content-center mt-3 mb-2" data-aos="zoom-in" data-aos-delay="1500" data-aos-once="true">
      <Milestone :variant="milestone_variant" :highlight="true" class="--size-166px" :milestone="milestone_computed" />
    </div>
    <p class="section-subtitle --big mb-4" data-aos="fade-down" data-aos-delay="2000" data-aos-once="true">
      <b>
        <i>
          <template v-if="milestone.play_streak">
            {{ $t('message.you-have-played') }}
            {{ $tc('plurals.day', milestone.play_streak) }}
            {{ $t('message.in-a-row') }} <br />
            {{ $t('message.keep-going') }}
          </template>
          <template v-else-if="milestone.points">
            {{ $t('message.you-have-reached-a-total-of') }}
            {{ $tc('plurals.points', milestone.points) }}! <br />
            {{ $t('message.keep-going') }}
          </template>
        </i>
      </b>
    </p>
    <p class="text-start mt-4 section-subtitle mt-5 px-4" data-aos="fade-right" data-aos-delay="3000" data-aos-duration="1000" data-aos-once="true">
      {{ $t('message.share-with-your-friends') }}
    </p>
    <ul class="list-group-horizontal d-flex px-4 mb-2" data-aos="fade-right" data-aos-delay="2750" data-aos-duration="1000" data-aos-once="true">
      <li v-for="social in socials" :key="social.id">
        <a target="_blank" @click="$emit('event', 'share', { on: social.title })" class="round-icon-wrapper bg-white" :title="$t('message.share-on', [social.title])" :data-action="dataAction(social.id)" :href="href(social.id)">
          <img width="25" height="25" :src="getSocialMediaIcon(social.icon)" :alt="social.title" />
        </a>
      </li>
    </ul>
    <div class="d-flex px-4 mt-4" data-aos="zoom-in" data-aos-delay="3000" data-aos-offset="-100">
      <button type="button" class="btn bg-orange-lighter flex-fill rounded-button" @click="share">
        {{ $t('message.share-now') }}
      </button>
    </div>
    <Certification :userModel="userModel" ref="certification" />
  </section>
</template>
<script>
import Milestone from '@/components/Milestone.vue';
import { toBlob } from 'html-to-image';
import Certification from '@/components/containers/Certification.vue';

export default {
  props: { userModel: { type: Object, required: true } },
  components: {
    Milestone,
    Certification,
  },
  data: () => ({
    socials: [
      { id: 'email', title: 'Email', icon: 'Mail' },
      { id: 'messenger', title: 'Messenger', icon: 'Messenger' },
      { id: 'twitter', title: 'Twitter', icon: 'Twitter' },
      { id: 'facebook', title: 'Facebook', icon: 'Facebook' },
      { id: 'whatsapp', title: 'Whatsapp', icon: 'WhatsApp' },
    ],
  }),
  computed: {
    milestone() {
      return this.userModel.last_milestone;
    },
    milestone_variant() {
      if (this.userModel.last_milestone.play_streak) {
        return 'play-streak';
      } else if (this.userModel.last_milestone.points) {
        return 'points';
      }
      return '';
    },
    milestone_computed() {
      if (this.userModel.last_milestone.play_streak) {
        return { value: this.userModel.last_milestone.play_streak };
      } else {
        return { value: this.userModel.last_milestone.points };
      }
    },
  },
  methods: {
    getSocialMediaIcon(icon) {
      return require(`@/assets/images/${icon}.svg`);
    },
    share() {
      this.$emit('event', 'share', { on: 'navigator share api' })

      this.$refs['certification'].$refs['parent'].classList.add('rendering');

      toBlob(this.$refs['certification'].$refs['parent']).then((blob) => {
        this.$refs['certification'].$refs['parent'].classList.remove('rendering');

        var file = new File([blob], 'qotd-award.jpg', {
          type: 'image/jpeg',
        });
        var filesArray = [file];

        try {
          if (navigator.canShare && navigator.canShare({ files: filesArray })) {
            navigator.share({
              title: this.$t('message.lets-play') + ' ' + this.$t('app.title'),
              files: filesArray,
              text: this.getMessage(),
              url: this.getLink(),
            });
          }
        } catch (err) {
          console.error(err);
        }
      });
    },
    href(id) {
      switch (id) {
        case 'email':
          return this.hrefEmail();
        case 'whatsapp':
          return this.hrefWhatsApp();
        case 'facebook':
          return this.hrefFacebook();
        case 'twitter':
          return this.hrefTwitter();
        case 'linkedin':
          return this.hrefLinked();
        case 'messenger':
          return this.hrefMessenger();
      }
    },
    dataAction(id) {
      switch (id) {
        case 'whatsapp':
          return 'share/whatsapp/share';
      }
    },
    getMessage() {
      let message = 'message.share-description-play-streak';

      if (this.milestone_variant == 'points') {
        message = 'message.share-description-points';
      }
      return this.$t(message, [this.milestone_computed.value]);
    },
    getLink(encode) {
      const link = 'https://profile.askqotd.com/' + this.$root.$i18n.locale + '/profile/' + this.$route.params.profile_id;
      if (encode) {
        return encodeURIComponent(link);
      }
      return link;
    },
    hrefMessenger() {
      const link = this.getLink(true);
      let href = `fb-messenger://share?link=${link}`;
      return href;
    },
    hrefTwitter() {
      const link = this.getLink(true);
      let Body = this.$t('message.lets-play') + ' ' + this.$t('app.title') + '! \n';
      Body += this.getMessage() + '\n';
      const HashTags = 'alexa,questionOfTheDay';
      let href = `http://twitter.com/share?text=${Body}&url=${link}&hashtags=${HashTags}`;
      return href;
    },
    hrefFacebook() {
      const link = this.getLink(true);
      let href = 'https://www.facebook.com/sharer/sharer.php?u=' + link;
      return href;
    },
    hrefWhatsApp() {
      let Body = this.$t('message.lets-play') + ' ' + this.$t('app.title') + '! ' + this.getLink();
      Body += this.getMessage();
      return 'whatsapp://send?text=' + Body;
    },
    hrefEmail() {
      const Subject = 'Alexa, ' + this.$t('app.title');
      let Body = '';
      Body += this.$t('message.lets-play') + ' ' + this.$t('app.title') + '! \n';
      Body += this.getMessage();
      Body += this.getLink();
      return `mailto:?Subject=${Subject}&body=${encodeURIComponent(Body)}`;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/custom-vars/_colors.scss';

#congratulations {
  background: $gradient-literature;
}
</style>
