<template>
  <section id="alexa-ads" class="pt-4 pb-5 text-black px-4 force-limit-width">
    <SectionTitleHeader :title="$t('message.try-our-alexa-skill')" color="literature" textColor="black" class="pt-4 mb-5" />
    <div class="d-flex two-columns-wrapper align-items-stretch">
      <div class="d-flex flex-column">
        <Ballon :text="$t('message.alexa-skill-prompt')" class="mb-5 mx-3" />
        <article class="legend --big text-black text-start font-weight-regular mb-0 mt-4 pt-4" style="width: 183px">
          <p data-aos="fade-right">
            <b>
              <i>{{ $t('message.alexa-description-1') }}</i>
            </b>
          </p>
          <p data-aos="fade-right" data-aos-delay="250">
            {{ $t('message.alexa-description-2') }}
          </p>
        </article>
      </div>
      <div class="d-flex flex-column justify-content-center" data-aos="fade-left">
        <img alt="Alexa Echo Dot Image" class="mx-4" :src="alexaImg" width="260" height="260" />
        <div class="alexa-dot-shadow"></div>
      </div>
    </div>
    <div class="d-flex mt-4" data-aos="zoom-in" data-aos-offset="-100">
      <a
        @click="$emit('event', 'click', { button: 'alexa_ad' })"
        type="button"
        href="https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.0f26244c-7411-4c2c-8401-d808c57eedef/launch"
        class="d-flex align-items-center justify-content-center btn bg-orange-lighter flex-fill rounded-button"
      >
        {{ $t('message.ask-now') }}
      </a>
    </div>
  </section>
</template>
<script>
import Ballon from '@/components/Ballon.vue';
import SectionTitleHeader from '@/components/SectionTitleHeader.vue';

export default {
  props: { userModel: { type: Object, required: true } },
  components: { Ballon, SectionTitleHeader },
  computed: {
    alexaImg() {
      return require('@/assets/images/alexa-dot.png');
    }
  }
};
</script>
<style scoped lang="scss">
@import '@/assets/custom-vars/_colors.scss';

#alexa-ads {
  background: $gradient-geography;

  .two-columns-wrapper {
    > div {
      width: 50%;
      img {
        z-index: 2;
      }
      .alexa-dot-shadow {
        z-index: 1;
        width: 250px;
        height: 70px;
        background: radial-gradient(50% 50% at 50% 50%, #1e668e 0%, rgba(30, 136, 142, 0) 100%);
        transform: translate(16px, -54px);
      }
    }
  }
}
</style>
