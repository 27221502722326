<template>
  <div class="home">
    <div v-if="loading" class="loading d-flex justify-content-center align-items-center" data-aos="zoom-up">
      <div class="loading-square"></div>
    </div>
    <main v-if="userModel" data-aos="fade-up" data-aos-once="true">
      <AppTitleHeader :textClass="userModel.last_milestone ? 'text-literature' : 'text-science'" />
      <Congratulations v-if="userModel.last_milestone" class="home-section" :userModel="userModel" @event="sendEvent" />
      <Statistics v-if="userModel" class="home-section" :userModel="userModel" @event="sendEvent" />
      <Milestones v-if="userModel" class="home-section" :userModel="userModel" @event="sendEvent" />
      <AlexaAd class="home-section" :userModel="userModel" @event="sendEvent" />
      <AppAd v-if="$i18n.locale === 'en'" class="home-section" :userModel="userModel" @event="sendEvent" />
    </main>
  </div>
</template>

<script>
import Congratulations from '@/components/containers/Congratulations.vue';
import Statistics from '@/components/containers/Statistics.vue';
import Milestones from '@/components/containers/Milestones.vue';
import AlexaAd from '@/components/containers/AlexaAd.vue';
import AppAd from '@/components/containers/AppAd.vue';
import userModel from '@/models/user.model.js';
import AppTitleHeader from '@/components/AppTitleHeader.vue';
import mixpanel from 'mixpanel-browser';

export default {
  name: 'Profile',
  metaInfo() {
    return {
      title: this.$t('app.title'),
      titleTemplate: '%s | ' + this.$t('app.profile'),
      htmlAttrs: {
        lang: this.$root.$i18n.locale,
        amp: true
      }
    };
  },
  computed: {
    logoImg() {
      return `url(${require('@/assets/images/qotd-512px-white-bg-2.jpg')})`;
    }
  },
  data: () => ({
    loading: true,
    userModel: false
  }),
  mounted() {
    this.loadData();
  },
  methods: {
    sendEvent(action, properties) {
      try {
        const data = { action: action, ...properties };
        console.log('stp_profile_event', data);
        mixpanel.track('stp_profile_event', data);
      } catch (e) {
        console.error(e);
      }
    },
    loadData() {
      const profile_id = this.$route.params.profile_id;
      try {
        mixpanel.init('e4cc2959721dc3dca351dc9f7ae2ed7a');
        mixpanel.identify(profile_id);
        this.sendEvent('visit');
      } catch (e) {
        console.error(e);
      }
      userModel.get(this.axios, profile_id, this.$route.query).then(data => {
        this.userModel = data;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    }
  },
  components: {
    Congratulations,
    Statistics,
    Milestones,
    AlexaAd,
    AppAd,
    AppTitleHeader
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars/_colors.scss';

.home {
  min-height: 100vh;
  > main {
    overflow-x: hidden;
    > .app-title-header {
      left: 1.5rem;
      position: absolute;
      height: 21px;
      z-index: 3;
      border-radius: 10px;
      > p {
        margin: 0px;
        margin-left: 21px;
      }
      > .app-logo {
        background-color: white;
        position: absolute;
        width: 34px;
        height: 34px;
        transform: translateX(-17px);
        background-position: center center;
        background-size: 28px 28px;
        background-repeat: no-repeat;
        border-radius: 50%;
      }
    }
    > section {
      &:not(:first-of-type) {
        margin-top: -20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      &:first-of-type {
        padding-top: 8px;
      }
      &#congratulations + section {
        box-shadow: 0px -2px 4px rgba(129, 15, 43, 0.6);
      }
      &#statistics + section {
        box-shadow: 0px -2px 4px rgba(11, 126, 85, 0.6);
      }
      &#milestones + section {
        box-shadow: 0px -2px 4px rgba(113, 117, 209, 0.6);
      }
      &#alexa-ads + section {
        box-shadow: 0px -2px 4px rgba(4, 125, 134, 0.6);
      }
    }
  }
  .loading {
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    position: fixed;
    background: $gradient-literature;
  }
}

.horizontal-scroll-section {
  width: 100%;
  height: 150px;
}
</style>
